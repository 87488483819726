/**
 * Testimonials section slider with splideJS
 * @source : https://splidejs.com/
 */

if (document.querySelectorAll('#slider-testimonials').length) {

  document.addEventListener( 'DOMContentLoaded', function () {

    var realisationsSlider = new Splide( '#slider-testimonials', {
      type        : 'slide',
    	perPage     : 1,
    	perMove     : 1,
      pagination  : true,
      arrows      : false,
      autoHeight  : true
    });

    realisationsSlider.mount();

  });

}
