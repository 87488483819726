/* ==========================================================================
  main.js
========================================================================== */

'use strict';
/* global fitvids, cssVars */

/**
 * Init responsive videos
 */
fitvids();

/**
 * Init CSS vars ponyfill
 */
cssVars({});

/**
 * Smooth scroll
 */
document.querySelectorAll('.anchor-container__link').forEach(trigger => {
   trigger.onclick = function(e) {
       e.preventDefault();
       let hash = this.getAttribute('href');
       let target = document.querySelector(hash);
       let headerOffset = 100;
       let elementPosition = target.offsetTop;
       let offsetPosition = elementPosition - headerOffset;

       window.scrollTo({
           top: offsetPosition,
           behavior: "smooth"
       });
   };
 });
